/*OLD COLORS*/
$white: #ffffff;
$table-head-bg: rgba(120, 196, 183, 1);
$table-head-even-bg: rgba(107, 182, 169, 1);
$table-td-even-bg: rgba(245, 245, 245, 1);
$section-button-green-bg: rgba(84, 177, 93, 1);
$section-button-green-bg-hover: rgba(84, 177, 93, 0.8);
$base-blue-color: rgba(35, 48, 93, 1);
$base-blue-color-disable: rgba(35, 48, 93, 0.3);
$mujo-blue-color: rgba(45, 85, 151, 1);
$default-orange-color: rgb(219, 172, 38);
$gray-bg-form: rgba(238, 238, 238, 1);
$default-red: rgba(203, 73, 72, 1);
$drag-icon-color: rgba(170, 170, 170, 1);
$gray-border-color: rgba(221, 221, 221, 1);

$gray-text-color: rgba(35, 48, 93, 1);
$error-color: rgb(244, 67, 54);

$base-label-color: rgba(0, 0, 0, 0.46);
$disabled-label-color: rgba(0, 0, 0, 0.16);

/*NEW COLORS*/
$yellow-header: #e9b730;
$navy-text: #23305d;
$navy-header: #23305d;
$white-text: #ffffff;
$orange-header: #d58128;
$teal-header: #7fc3b7;
$dark-teal-header: #6fb6a9;
$blue-button: #2d5597;
$white-button: #ffffff;
$blue-text: #2d5597;
$grey-text: #aaaaaa;
$green-button: #54b15d;
$red-button: #cb4948;
$light-gray-background: #eeeeee;
$grey-background: #e1e1e1;
$blue-background: #6baad1;
$white-background: #ffffff;
$compilance-score-background: #54b15d;
$inactive-bottom-bars: #dddddd;
$inactive-text: #bbbbbb;
$exercise-image-lines: #000000;


/*IMPROVED HEADERS*/
$page-header: #23305d;
$blue-section-header: #2d5597;
$light-blue-subsection-header: #6baad1;
$date-selected-background: $blue-background;
$red-header: #9c0a0e;