@import "include/colors";
@import "include/base";
@import "include/fonts";
@import "include/media-queries";
@import "include/_mobile";


body, html {
    @include box-sizing(border-box);
    padding: 0;
    margin: 0;
    height: 100%;
}

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

.clearfix {
    display: inline-block;
}

/* start commented backslash hack \*/
* html .clearfix {
    height: 1%;
}

.clearfix {
    display: block;
}

.full-width {
    @include box-sizing(border-box);
    width: 100%;
    max-width: 1024px;
    margin: 0;
}

.checkbox-group {
    div[ng-messages] {
        flex: 0 0 100%;
        color: $error-color;
        font-size: 16px;
        line-height: 24px;
    }

    &.md-input-invalid {
        > div:first-child {
            border-bottom: solid 1px $error-color;
        }
    }
}

.welcome {

    .welcome-image {
        width: 100%;
    }
}

.cd-input-row {
    /*margin-top: -16px;*/
    padding: 2px;
    padding-bottom: 26px;
    height: 80px;
}

.cd-select-input {
    @extend .cd-input-row;
}

.cd-radio-input {
    @extend .cd-input-row;
}

.cd-subhead {
    @extend .cd-input-row;
    font-weight: bold;
}

.cd-info-row {
    margin-bottom: 10px;
}

.cd-gray {
    background-color: rgba(0, 0, 0, 0.1);

    * {
        background-color: transparent;
    }
}

.cd-input-label {
    overflow: hidden;
    line-height: 75px;
    max-height: 50px;
    display: inline-block;
}

.cd-textarea {
    min-height: 100px;
}

.cd-align-right {
    text-align: right;
}

.padding {
    padding: 0 10px;
    overflow: visible;
}

.space-right {
    padding-right: 10px;
}

.space-left {
    padding-left: 10px;
}

.no-padding-bottom {
    padding-bottom: 0;
}

.bordered-section {
    border: 1px solid $gray-border-color;
    border-top: none;

    &.bordered-section-bottom {
        border: none;
        border-bottom: 1px solid $gray-border-color;
    }
}

md-card {
    margin: 8px 0;
}

md-content {
    overflow: visible;
}

.section-button {
    margin-left: 0;

    &:last-child {
        margin-right: 0;
    }
}

.base-blue {
    color: $mujo-blue-color;
}

.standard-link {
    font-size: 16px;
    color: $mujo-blue-color;
    cursor: pointer;
    text-decoration: underline;
    text-transform: none;
}

.md-input-invalid {
    md-select {
        .md-select-value {
            color: rgb(244, 67, 54) !important;
            border-bottom-color: rgb(244, 67, 54) !important;
        }
    }
}

.top-header {
    @include box-sizing(border-box);
    width: 100%;
    background: url('../img/bg.png') repeat 55px bottom;

    .inside {

        .site {
            font-size: 24px;
            cursor: pointer;
        }

        .nav-button {
            display: block;
            margin: 0;
            padding: 0;
            min-width: 10px;
            min-height: 10px;
            margin: 20px;
        }

        .header-back {
            height: 25px;
            width: 23px;
        }

        .header-account {
            height: 26px;
            width: 23px;
            background-position-x: -24px;
        }

        .header-logout {
            height: 25px;
            width: 25px;
            background-position-x: -48px;
        }

        .common-header {
            padding: 0 20px;
        }
    }
}

.footer {
    margin-top: 30px;
}

.sprite {
    background: url('../img/sprite.png') no-repeat;
}

.sprite-after-before {
    &:after {
        content: " ";
        background: url('../img/sprite.png') no-repeat;
    }

    &:before {
        content: " ";
        background: url('../img/sprite.png') no-repeat;
    }
}

.table-exercises-list {
    .exercise-row {
        font-size: 14px;
        line-height: 1;

        &:first-child {
            div {
                margin-top: 20px;
            }
        }
    }
}

.row-section {
    margin-top: 30px;
}

.dialog-wrapper {
    max-width: 1024px;

    header {
        background-color: $navy-header;
        color: $white-text;
    }

    &.create-prescription {
        padding-bottom: 0;
    }

    &.code-generator {
        .account-code {
            text-align: center;
            position: relative;
        }

        .reload {
            display: block;
            height: 25px;
            width: 25px;
            background-position-x: -97px;
            margin-left: 15px;
            cursor: pointer;
        }
    }
}

.base-label {
    color: $base-blue-color;
    font-size: 16px;


    &.disabled {
        color: $base-blue-color-disable;
    }
}

input[type=number] {
    text-align: center;
}

.mb2 {
    margin-bottom: 2px;
}

.mb-1 {
    margin-bottom: -1px;
}

.mb-4 {
    margin-bottom: -4px;
}

.mb-5 {
    margin-bottom: -5px;
}

.mt-6 {
    margin-top: 6px;
}

.md-input-has-value + .cd-input-label {
    margin-bottom: -15px;
}

.md-select-value .md-select-icon {
    margin-bottom: -2px;
}

.label-center {
    text-align: center;
}

.md-radio-group {
    padding-bottom: 4px;
}

.drag-me {
    display: block;
    width: 22px;
    height: 2px;
    color: $drag-icon-color;
    border-top: 2px solid;
    border-bottom: 2px solid;
    margin: auto;

    &:before {
        content: " ";
        height: 2px;
        background: $drag-icon-color;
        display: block;
        margin-top: 6px;
    }
}

.account-code {
    font-family: $headline-font;
    font-weight: bold;
    text-align: center;
    font-size: 36px;
    letter-spacing: 6px;
}

.plain-text {
    color: $base-blue-color;
    font-size: 16px;
}

.preview-row {
    padding: 10px 0;
}

.label-view {
    @extend .base-label
}

.layout-row {
    > .flex-93 {
        max-width: 93%;
    }
}

.input-view {
    color: $base-blue-color;
    background-color: $gray-bg-form;
    border: 1px solid $gray-border-color;
    font-weight: bold;
    font-size: 16px;
    padding: 14px;
    word-wrap: break-word;

    .input-view-full {
        display: inline-block;
        width: 100%;
    }

    .address-row {
        display: inline-block;
        width: calc(100% - 5px);
    }

    &.margin-left {
        @include tablet-portrait-and-up {
            margin-left: 30px;
        }
    }
}

.flex-customize-1-6 {
    width: 16.5%;
}

.flex-customize-5-6 {
    width: 83.33%;
}

.edit-prescription {
    .purpose {
        margin-left: -10px;
    }

    .prescription-details {
        label, md-input-container {
            margin-right: 15px;
        }
    }
}

md-input-container {
    &.no-padding {
        padding: 0;
    }
}

.data-picker-container {
    padding-bottom: 26px;
}

.validation-error {
    position: relative;

    .md-datepicker-input-container {
        border-bottom-style: solid;
        border-bottom-color: rgb(244, 67, 54);
        color: rgb(244, 67, 54);

        input {
            color: rgb(244, 67, 54);
        }
    }

    .validation-messages {
        padding: 4px;
        color: rgb(244, 67, 54);

        &.validation-messages-date {
            margin-bottom: 0;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            text-align: center;
        }
    }
}

/* overwriting START */

table[md-data-table] {
    &.standard-table {
        font: normal 16px/18px Arial, sans-serif;
        color: $base-blue-color;

        thead {
            &:not([md-table-progress]) {
                > tr:not(.second-row) {
                    > th {
                        font: normal 16px/18px Arial, sans-serif;

                        &:nth-child(even) {
                        }

                        &[order-by] {
                            &:hover {
                                color: $white;
                            }

                            &.md-active {
                                color: $white;

                                > div {
                                    > md-icon {
                                        fill: $white;
                                    }
                                }
                            }
                        }
                    }
                }

                > tr.second-row {
                    > th {
                        font: normal 16px/18px Arial, sans-serif;

                        > div {
                            height: 49px;
                        }

                        &:nth-child(even):not(.important-red) {
                        }
                    }
                }
            }
        }

        > tbody {
            td {
                font: normal 16px/18px Arial, sans-serif;
                color: $navy-header;

                .important-red {
                    background-color: $default-red;
                    text-align: center;
                    padding: 0 5px;
                }

                .not-sent {
                    color: $default-red;
                    font-weight: bold;
                }

                .white-after-red {
                    background-color: $default-red;
                    color: $white;
                    font-weight: bold;
                    text-decoration: none;
                }
            }

            a:not(.md-button) {
                color: $mujo-blue-color;
                cursor: pointer;
                text-decoration: underline;
                margin: 0;
                padding: 0;
                text-align: left;
            }

            .md-ripple-container {
                transition: none;
            }
        }
    }
}

md-input-container {
    label {
        font-style: italic;
        font-size: 16px;

        @include mobile-landscape-and-below {
            font-size: 12px;
        }
    }

    &.md-icon-float {
        > label {
            margin-left: 20px;
            color: $base-label-color;
        }

        &.disabled-label {
            > label {
                color: $disabled-label-color;

                &:hover {
                    cursor: not-allowed;
                }
            }
        }
    }

    &.customize {
        padding-bottom: 2px;
    }
}

.disabled-label {
    color: $disabled-label-color;

    label {
        color: $disabled-label-color;
    }

    &:hover {
        cursor: not-allowed;
    }
}

.md-select-value {

    &.md-select-placeholder {

        span {
            color: $base-label-color;
        }
    }
}

md-select[disabled] {
    color: $disabled-label-color;

    .md-select-value {
        color: $disabled-label-color;

        &.md-select-placeholder {
            color: $disabled-label-color;

            span {
                color: $disabled-label-color;
            }
        }
    }
}

.md-dialog-container {

    .md-input-has-value {
        input {
            color: black !important;
        }
    }
}

.no-margin {
    margin: 0 !important;
}

.margin-auto {
    margin: 0 auto !important;
}

md-card {
    &.customize {
        margin: 20px 0;

        > section, > div > section {
            margin: 15px;
            width: calc(100% - 30px);

            &.no-margin {
                margin: 0;
                width: 100%;
            }
        }

        md-content {
            background: transparent;

            &.orange-head-section {
                .md-toolbar-tools {
                    background-color: $orange-header;

                    h3 {
                        color: $white;
                    }
                }

                &.gray-content {
                    .md-default-theme {
                        /*background-color: $gray-bg-form;*/
                    }
                }
            }
        }
    }
}

md-select {
    &.customize {
        .md-select-value, .md-select-placeholder {
            font-weight: bold;
            padding-left: 10px;
        }
    }
}

md-dialog {
    &.customize {
        border: 2px solid $drag-icon-color;
    }

    &.md-error-500-theme {
        border: 2px solid $default-red;

        .md-title {
            color: $default-red;
        }
    }
}

.error-message {
    border-top: 1px solid $error-color;
    padding: 10px;
    color: $error-color;
    margin-top: 10px;
    font-size: 12px;
}

.md-select-menu-container {
}

$top-spacing: 15px;

.spacing-top {
    margin-top: -$top-spacing;

    > .spacing {
        margin-top: $top-spacing;
    }
}


/* overwriting STOP */
/* --------------- */
/* login page START */
.login-wrapper {
    > .customize:first-child {
        min-height: calc(100vh - 650px);
    }
}

.login-footer-images {
}

/* login page STOP */
/* --------------- */
/* patient page START */
.patient-view {

    .not-logged {
        color: $default-red;
        background-color: $white;
        padding: 0 15px 10px 15px;
        width: 100%;
        margin: -5px 0 0;

        p {
            color: $red-button;
        }
    }

    .assigned-physios {
        .locum {
            color: $default-red;
            margin: 5px 0;
        }
    }

    .prescription-history {
        .standard-table {
            /*margin-bottom: 20px;*/
        }
    }

    md-input-container {
        width: 93%;
    }
}


.code-box-inside-wrapper {
    .code-row {
        margin-bottom: 8px;

        .account-code {
            text-align: center;
            position: relative;
        }

        .reload {
            display: block;
            height: 25px;
            width: 25px;
            background-position-x: -97px;
            margin-left: 15px;
            cursor: pointer;
        }
    }
}

.assign-patient {
    .standard-table {
        md-datepicker {
            background-color: transparent;
        }
    }
}

/* patient page STOP */
/* --------------- */
/* prescription START */
.edit-prescription {

    .under-table {
        margin-top: 20px;
    }

    .standard-table {
        .handle {
            width: 45px;
        }
    }
}

.prescription {
    .prescription-info {
        margin-bottom: 15px;

        .prescription-label {
            padding-top: 10px;
        }
    }

    .prescription-info-field {
        min-height: 60px;
    }

    .prescription-label {
        color: $gray-text-color;
        font-size: 16px;
        font-family: Arial, sans-serif;

        &.padding-left {
            padding-left: 20px;
        }
    }
    /*.prescription-field {
        @extend .prescription-label;
        font-weight: bold;
        background-color: $gray-border-color;
    }*/
    .standard-table {
        th {
            div {
                justify-content: center;
            }
        }

        td {
            text-align: center;
        }
    }

    .exercise-details {
        h3 {
            margin: 0;
        }

        .exercise-notes {
        }
    }
}

/* prescription STOP */

#createPrescriptionDialog {
    width: 1024px;
}

/* createPhysioDialog */
.create-physiotherapist {
    width: 600px;
}

/* createMultiselectDialog START */
.multi-select-popup {
    width: 800px;

    .popup {
        max-height: 375px;

        button {
            min-height: 70px;
            background: none;
            border: 3px solid $white;
            outline: none;
            padding: 5px;

            &.selected {
                background-color: $blue-background;
                /*font-weight: bold;*/
            }
        }
    }
}

/* createMultiselectDialog STOP */
/* --------------- */

/* --------------- */
/* logoutAlert START */
.logout-alert {
    width: 600px;

    p {
        font-size: 16px;
        margin-top: 10px;

        span {
            &.timeout {
                color: $default-red;
            }

            font-size: 16px;
        }
    }
}

/* logoutAlert STOP */
/* --------------- */

/* --------------- */
/* admin dashboard START */
.admin-dashboard {
    .dropdowns-card {
        .md-button {
            margin-top: 5px;

            &:first-child {
                margin-top: 0;
            }
        }

        .input-view {
            min-height: 47px;
        }
    }
}

/* admin dashboard STOP */
/* --------------- */

/* --------------- */
/* default exercise dialog START*/
.default-exercise-dialog {
    min-width: 920px;
}

.default-exercise-content {
    display: block;
    padding: 10px 20px 0 20px;
}

/*default exercise dialog STOP*/
/* --------------- */

.clickable {
    cursor: pointer;
}

/* --------------- */
/* exercise image START*/

.no-exercise-image {
    background-color: $gray-border-color;
    color: black;

    p {
        margin: 0;
        text-align: center;
    }
}

.exercise-image-wrapper {
    overflow-x: auto;
    width: 100%;
    text-align: center;
}

svg.exercise-image {
    display: inline-block;
    margin: 0 auto;
}

.exercise-image {
    border: solid 1px $gray-border-color;

    @include tablet-landscape-and-up {
        height: 400px;
        width: 900px;
        min-width: 900px;
    }

    @include tablet-portrait-and-below {
        height: 360px;
        width: 600px;
        min-width: 600px;
    }

    @include mobile-landscape-and-below {
        height: 320px;
        width: 400px;
        min-width: 400px;
    }

    &.hide-left {
        .left {
            display: none !important;
        }
    }

    &.hide-right {
        .right {
            display: none !important;
        }
    }

    &.hidden, .hidden {
        display: none;
    }

    text {
        font-size: 10px;
        text-anchor: middle;
    }

    #circle {
        fill: #94d31b;
    }

    line {
        stroke: $exercise-image-lines;
        transition: 1s ease-in-out;
    }

    g {
        transition: 0.5s ease-in-out;

        &.anchor-end {
            text {
                transition: 0.5s ease-in-out;
                text-anchor: end;
            }
        }

        &.anchor-start {
            text {
                text-anchor: start;
            }
        }
    }

    rect {
        transition: .5s ease-in-out;
        fill: #efefef;
        stroke: $exercise-image-lines;
    }

    .circle {
        transition: .5s ease-in-out;
        r: 20px;
        fill: none;
    }

    .circle.extend {
        fill: none;
    }

    .circle.return {
        fill: none;
    }

    path {
        transition: .5s ease-in-out;
        stroke: black;
        stroke-dasharray: 4 4;
    }

    path.return {
        stroke: darkgray;
    }

    polyline {
        transition: .5s ease-in-out;
        stroke: red;
    }
}

/* --------------- */
/* exercise image STOP*/
/* --------------- */

/* --------------- */
/* manage dropdowns START */
.manage-dropdowns {
    .dropdowns-card {
        .md-raised {
            margin-bottom: 5px;
        }

        .input-view {
            min-height: 47px;
        }

        md-input-container {
            padding-bottom: 0px;
        }

        .explanations {
            font-size: 11px;
            font-style: italic;
        }
    }
}
/* manage dropdowns STOP */
/* --------------- */

/* --------------- */
/* manage dropdown modal START */
.manage-dropdown {
    md-input-container {
        padding-bottom: 0px;
    }

    .explanations {
        font-size: 11px;
        font-style: italic;
    }
}
/* manage dropdown modal STOP */
/* --------------- */

/* --------------- */
/* patient-report-view START*/
.patient-report-view {
    .score {
        background-color: $compilance-score-background;
        color: $white-text;
        width: 80px;
        height: 80px;
        display: table;

        span {
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            font-size: 28px;
        }
    }

    .standard-table {
        .sub-head {
            td {
                background-color: $light-gray-background !important;
            }
        }
    }

    .chart-type {
        &.active {
            height: 63px;
            border-bottom: 5px solid $default-red;
            border-radius: 0px;
        }

        &.active:after {
            content: '';
            width: 20px;
            height: 20px;
            background: $default-red;
            position: absolute;
            -moz-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            top: 55px;
            left: 86px;
        }
    }

    .chart {
        width: 100%;

        .nvd3-svg {
            .multiChart {
                .nv-x {
                    display: none;
                }
            }
        }

        &.line-chart {
            height: 250px;
        }

        .chart-set {
            width: 100%;

            h3 {
                display: table-row;
            }

            > div {
                display: table-cell;
                text-align: center;
            }
        }

        .tick line {
            display: none;
        }

        .x {
            display: none;
        }
    }

    .no-results {
        text-align: center;
        line-height: 100px;
    }

    .chart-sessions-x-axis-desc {
        display: table;
        width: 100%;
        padding-left: 70px;
        padding-right: 60px;

        div {
            display: none;
            padding: 0;
            text-align: center;
            font: normal 12px Arial;
        }

        margin-bottom: 20px;
    }
}

.movement-chart-container {
    .chart-content {
        height: 250px;
    }

    .chart-content-container {
        height: 250px;
        overflow-x: auto;
    }
}
/* patient-report-view STOP*/

/* --------------- */

md-progress-circular {
    margin-left: auto;
    margin-right: auto;
}


.md-pane-open {
    .md-datepicker-calendar {
        background: white;

        .md-calendar-date-today {
            .md-calendar-date-selection-indicator {
                font-weight: bold;
                border: solid 1px $date-selected-background;
            }
        }

        .md-calendar-selected-date {
            .md-calendar-date-selection-indicator {
                background-color: $date-selected-background;
                color: $white-text;
            }
        }
    }
}

.centered-content {
    text-align: center;
    padding: 10px;
}

.nv-y2 {
    display: none;
}

.set-finished {
    color: #54b15d;
}

.set-skipped {
    color: #cb4948;
}

.chrome-container {
    padding-top: 30px;
}

.mujo-logo {
    margin: auto;
    height: 150px;
    width: 50%;
    background: url("../img/MuJoLogo.png");
    background-repeat: no-repeat;
    background-size: 90%;
}


.chrome-text {
    background: $light-gray-background;
    margin: 30px auto;
    padding: 30px;
    text-align: center;
    width: 50%;
    font-family: $headline-font;
    font-size: 25px;
    line-height: 1.5;

    .chrome-logo {
        margin: 20px auto;
        background: url('../img/Chrome-Logo.png') no-repeat;
        height: 50px;
        width: 10%;
    }
}


@-moz-document url-prefix() {
    #login-image {
        max-height: none;
    }
}


_:-ms-lang(x), #login-image{
    max-height: 90%;
}
