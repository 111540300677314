@import "media-queries";

@include tablet-landscape-and-below {
    .report-movement-chart {
        display: block;
    }

    .top-header {
        display: table;

        .inside, .login-header-background {
            overflow: hidden;
            margin: 0 auto;
        }

        div {
            @include box-sizing;
        }
    }

    md-dialog {
        min-width: 600px;
    }

    @-moz-document url-prefix() {
        #login-header-image {
            min-height: 50%;
        }
    }
}

@include mobile-landscape-and-below {
    md-input-container {
        label {
            font-style: italic;
            font-size: 12px;
        }
    }

    md-dialog {
        width: 90% !important;
        min-width: 285px;
    }

    @-moz-document url-prefix() {
        #login-header-image {
            min-height: 86%;
            max-width: 95%;
        }
    }
}

@include desktop-and-up {
    .change-password {
        width: 600px;
    }

    md-dialog {
        min-width: 600px;
    }

    @-moz-document url-prefix() {
        #login-header-image {
            min-height: 50%;
        }
    }
}
