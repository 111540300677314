@import "colors";
@import "fonts";
@import "media-queries";
@import "mixins";

.md-toolbar-tools, .navy-header {
    background-color: $navy-header;

    h1, h2, h3, h4 {
        color: $white-text;
        font-family: $headline-font;
        font-weight: bold;
        font-size: 24px;
    }
}

.blue-header {
    background-color: $blue-section-header;

    h1, h2, h3, h4 {
        color: $white-text;
    }
}

.light-blue-header {
    background-color: $light-blue-subsection-header;

    h1, h2, h3, h4 {
        color: $white-text;
    }
}

.grey-background {
    background-color: $grey-background;
}

.grey-header {
    @extend .grey-background;

    h1, h2, h3, h4 {
        color: $navy-text;
    }
}

.red-header {
    background-color: $red-header;
}

/*
HEADER
*/
$sticky-header-margin: 20px;

.header-sticky {
    position: fixed;
    top: -$sticky-header-margin;
    z-index: 3;
}

.page-header {
    background-color: $page-header;
    margin-top: $sticky-header-margin !important;
    padding: 0 15px;

    h1, h2, h3, h4 {
        color: $white-text;
        font-family: $headline-font;
        font-weight: bold;
        font-size: 24px;
        padding-right: 15px;
    }
}

header {
    @include box-sizing;
    padding: 0 15px;
    color: $navy-text;
    overflow: hidden;

    &.top-header {
        @include box-sizing;
        padding: 0;
        font-family: $headline-font;
        color: $white;

        display: table;

        .inside, .login-header-background {
            overflow: hidden;
            margin: 0 auto;
        }

        div {
            @include box-sizing;
        }

        .title {
            margin-bottom: 0;
            @include tablet-portrait-and-up {
                font-size: 32px;
            }

            @include mobile-landscape-and-below {
                font-size: 24px;
            }

            @include mobile-portrait-and-below {
                font-size: 22px;
            }
        }
    }

    &.page-header {
        background-color: $page-header;
        @include box-sizing;

        h2, h3, h4 {
            color: $white-text;
        }
    }

    &.section-header {
        background-color: $blue-section-header;

        h2, h3, h4 {
            color: $white-text;
        }
    }

    &.other-section-header, &.subsection-header {
        background-color: $light-blue-subsection-header;

        h2, h3, h4 {
            color: $white-text;
        }
    }

    h1, h2, h3, h4 {
        margin-top: 20px;
        margin-bottom: 20px;
        font-family: $headline-font;
        font-weight: bold;
        font-size: 24px;
        padding-right: 15px;
    }

    .login-header-background {
        height: 225px;

        @include tablet-portrait-and-up {
            height: 400px;
            background-repeat: no-repeat;
            background-position: top right;
            background-image: url('../img/login-header-bg.png');
        }
    }
}

/*
TABLE
*/
.table-section {
    overflow-x: auto;
}

table[md-data-table] {
    font: normal 16px/18px Arial, sans-serif;

    /*HEADER*/
    th[md-column-header] {
        &:nth-child(even) {
            background-color: $dark-teal-header;
            color: $white-text;
        }

        &:nth-child(odd) {
            background-color: $teal-header;
            color: $white-text;
        }
    }
    /*ROW*/
    tr[md-table-row], tr.second-row {
        color: $navy-text;

        td[md-table-cell], th[md-column-header] {
            color: $navy-text;

            &:nth-child(even) {
                background-color: $light-gray-background;
            }

            &:nth-child(odd) {
                background-color: $white-background;
            }
            &.half-width {
                width: 50%;
            }
        }

        &:hover {
            td[md-table-cell], th[md-column-header] {
                color: $navy-text;

                &:nth-child(even) {
                    background-color: $grey-background;
                }

                &:nth-child(odd) {
                    background-color: $light-gray-background;
                }
            }
        }
    }

    &.half-width {
        width: 50%;
    }

    .action-cell {
        text-align: center;

        button.md-button {
            width: 130px;
        }
    }
}

/*
BUTTON
*/
.md-button {
    &.md-raised, &.md-raised:not([disabled]) {
        font: bold 16px/36px Arial, sans-serif;
        text-transform: none;

        &.green {
            color: $white-text !important;
            background-color: $green-button !important;
        }

        &.white {
            background-color: $white !important;
            color: $base-blue-color !important;
        }

        &.red {
            background-color: $red-button !important;
            color: $white !important;
        }

        &.blue {
            background-color: $blue-button !important;
            color: $white-text !important;
        }
    }

    &.md-raised[disabled] {
        background-color: $inactive-bottom-bars !important;
        color: $grey-text !important;
    }

    &.customize {
        text-transform: none;
    }
}

.layout-padding {
    padding: 15px;
}

input[type=number] {
    position: relative;
}

input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    cursor: pointer;
    display: block;
    width: 10px;
    text-align: center;
    position: absolute;
    right: 0;
    top: 0;
    height: 26px;
    line-height: 26px;
    box-sizing: border-box;
}

input[type=number]::-webkit-inner-spin-button:before,
input[type=number]::-webkit-inner-spin-button:after {
    display: block;
    content: '';
    position: relative;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 10px 5px;
    border-color: transparent transparent #000 transparent;
}

input[type=number]::-webkit-inner-spin-button:before {
    top: 2px;
}

input[type=number]::-webkit-inner-spin-button:after {
    bottom: -8px;
    -webkit-transform: rotate(180deg);
}